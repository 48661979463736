/* gs445w.css */

/* Ensure container does not exceed screen width and is centered */
.container {
    max-width: 1200px; /* Adjust as needed for your design */
    padding-top: 5rem;
    padding-left: 1rem; /* Adjust for mobile responsiveness */
    padding-right: 1rem; /* Adjust for mobile responsiveness */
    margin: 0 auto; /* Center the container horizontally */
}

/* Flexbox layout for image and details */
.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.md\:flex-row {
    flex-direction: row;
}

.items-start {
    align-items: flex-start;
}

.gap-\[3rem\] {
    gap: 3rem;
}

/* Image styling */
img {
    max-width: 100%; /* Ensure images are responsive */
    height: auto;
    border-radius: 8px;
}

/* Specific class for the smaller image */
.img-small {
    width: 50%; /* Set the width to 50% of its container */
    margin-bottom: 15px;
}

/* Center image container */
.image-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically, if needed */
}

/* Ensure image container doesn’t exceed its width */
.flex-shrink-0 {
    flex-shrink: 0;
}

/* Custom styles for heading and text */
.text-baseblack {
    color: #000;
}

.text-gray-700 {
    color: #4a4a4a;
}

/* Increase font size and line spacing for details section */
.text-details {
    font-size: 16px; /* Adjust font size as needed */
    line-height: 1.75; /* Adjust line spacing */
    margin-top: 1rem; /* Optional: additional spacing above the text */
}

.font-bold {
    font-weight: 700;
}

.mb-4 {
    margin-bottom: 1rem;
}

.border-gray-300 {
    border-color: #d1d5db;
}

.shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Media query to stack image and details on small screens */
@media (max-width: 768px) {
    .flex {
        flex-direction: column;
    }

    .md\:flex-row {
        flex-direction: column;
    }

    .detail1 {
        width: 100%;
        max-width: none; /* Remove max-width restriction */
    }

    .detail2 {
        width: 100%;
        margin-bottom: 5px;
    }
}
