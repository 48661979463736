* {
    margin: 0;
    padding: 0;
    border: 0;
}

.main-container {
    width: 100%;
}

.landing-content {
    text-align: left;
}

.banner img:hover {
    filter: grayscale(0%) !important;
}
