body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styling for Swiper navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 20px; /* Adjust the font size for arrow icons */
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.swiper-button-prev::before {
  content: "<"; /* Unicode for left arrow */
  color: #fff;
  margin-top: -5px;
  transform: scaleY(1.5);
}

.swiper-button-next::before {
  content: ">"; /* Unicode for right arrow */
  color: #fff;
  margin-top: -5px;
  transform: scaleY(1.5);
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}
